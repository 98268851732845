import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import shobdoLogo from '../asset/logo/shobdo.svg';
import imLogo from '../asset/logo/im.svg';
import { SectionStyle } from '../style/SectionStyle';
import { IM_URL, PRIVACY_POLICY_URL, SUPPORT_URL } from '../constant/Urls';

const useStyles = makeStyles(SectionStyle);

const Footer = () => {
	const classes = useStyles();

	return (
		<footer className={classes.footer} style={{ backgroundColor: '#FAFBFF' }}>
			<Container className={classes.footerScreen}>
				<Grid
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item>
						<img
							src={shobdoLogo}
							style={{ width: '120', cursor: 'pointer' }}
							alt='shobdo-logo'
							onClick={() =>
								window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
							}
						/>
					</Grid>

					<Grid item>
						<Grid
							container
							direction='row'
							justify='space-between'
							alignItems='center'
							spacing={8}
						>
							<Grid item>
								<Typography
									style={{
										cursor: 'pointer',
										marginTop: 16,
										fontSize: 18,
										color: '#6236FF'
									}}
									onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
								>
									Privacy Policy
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									style={{
										cursor: 'pointer',
										marginTop: 16,
										fontSize: 18,
										color: '#6236FF'
									}}
									onClick={() => window.open(SUPPORT_URL, '_blank')}
								>
									Support
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<img
							src={imLogo}
							style={{ width: '120', cursor: 'pointer' }}
							alt='im-logo'
							onClick={() => window.open(IM_URL, '_blank')}
						/>
					</Grid>
				</Grid>
			</Container>
		</footer>
	);
};

export default Footer;
